/**
 * Reduce store for share objects 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 17/03/2020
 * 
 * Copyright 2020 Zenite Tecnologia.
 */
import {createStore} from 'redux';
import {Reducers} from '../reducers';

export const Store = createStore(Reducers);