/**
 * Session helper for all app 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 17/06/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */
//import md5 from 'md5';
import {configRequest, removeConf, get, headers, Api} from './net/NetworkUtils';
import socketIOClient from 'socket.io-client';
import {fetchMissedCall} from './util/Utils';
import hash from './util/security';
import { isMobile } from 'react-device-detect';

// API url
export const API_URL = "https://adm.zipy.com.br/api";
// IO
export const SK_HOST = 'https://adm.zipy.com.br';
export const SK_PORT = 35000;
//sk
let io = null;
// user rights
let userRights;
// user status auth
export const STATUS = {
  OK: 'OK',
  RECOVERY: 'RECOVERY',
  FAULT: 'FAULT',
  INVALID_EXTEN: 'INVALID_EXTEN',
  FORBIDDEN: 'FORBIDDEN'
};
// IO EVENTS
export const EVENT = {
  MISSED_CALL: 'new:call',
  CHANNEL_CHANGE: 'new:channel',
  CHANNEL_OUTGOING: 'CHANNEL_OUTGOING',
};
// user master
const LVL_USER_MASTER = 3;
const LVL_USER_ADMIN = 1;

// tag storage rights
const TAG_RIGHTS = 'EbkQKwJCY7atmWHeV4dO';
// tag user data
const TAG_USER = "_X58zxtcF2enQ7ji8tyhT";
// tag has auth
const TAG_AOTH = "X58zxtcF2enQ7ji8tyh_AOT"
// tag remember user
export const TAG_EMAIL = 'C59YhW4yoiR3eUYaYYuV';
// tag branch
export const TAG_BRANCH = 'TfI0R5WHxfnZ9luBgXjt';
// tag session news
export const TAG_SESSION_NEWS = "sess_nTo";
// tag user exten
export const TAG_USER_EXTEN = 'exten';
// define what storage use
export const storage = () => {
  let _store = sessionStorage;
  if(localStorage.getItem('remember') === 'true'){
    _store = localStorage;
  }
  return _store;
}

// persist user object in session
export const persistUser = (user) => {
  const u = {
    id: user.id,
    username: user.name||user.username,
    email: user.email,
    domain: user.domain_uuid||user.domain,
    lvl: user.lvl,
    domain_name: user.domain_name,
    domain_dashboard_age: user.dashboard_age||user.domain_dashboard_age,
    settings: user.settings,
    address: user.address,
    wa: user.wa_number
  };
  storage().setItem(TAG_USER, btoa(JSON.stringify(u)));
  return u;
};

// persist rights
export const persistRights = (rights) => {
  userRights = rights;
  document.dispatchEvent(new CustomEvent("rightsok", {detail: 'OK'}));
  let rs = rights.map(r => r.path);
  storage().setItem(TAG_RIGHTS, btoa(JSON.stringify(rs)));
};

// response to login
const responseLogin = (b, callback) => {
  setTimeout(() => callback( b ), 200);
};

// dispatch io events
const dispatch = (event,data) => {
  if(event === EVENT.MISSED_CALL){
    fetchMissedCall();
  }else if(event === EVENT.CHANNEL_CHANGE){
    document.dispatchEvent(new CustomEvent(EVENT.CHANNEL_CHANGE, {detail: data}));
  }
};

/**
 * Result for login
 */
const loginResult = (res, params, cb) => {
  if(!res.error){
    // user in recovery mode
    if(res.data.recovery){
      return responseLogin({status: STATUS.RECOVERY}, cb);
    }else if(res.data.invalid_exten){
      return responseLogin({status: STATUS.INVALID_EXTEN}, cb);
    }else if(res.data.forbidden){
      return responseLogin({status: STATUS.FORBIDDEN}, cb);
    }
    // store remember
    localStorage.setItem("remember", params.remember);
    if(params.remember){
      localStorage.setItem(TAG_EMAIL, btoa(params.email));
    }else{
      localStorage.removeItem(TAG_EMAIL);
    }
    // store exten?
    if(params.exten && params.exten !== '' && res.data.user.ext){
      localStorage.setItem(TAG_USER_EXTEN, params.exten);
    }else{
      // check to remove 
      if(params.exten === '' && localStorage.getItem(TAG_USER_EXTEN)){
        localStorage.removeItem(TAG_USER_EXTEN);
      }
    }
    // set localStorage values
    const persist = persistUser(res.data.user);
    // next HTTP request using this settings
    configRequest({
      headers: {
        'Authorization': `Bearer ${res.data.token}`
      }
    });
    // user news
    sessionStorage.setItem(TAG_SESSION_NEWS, res.data.user.news.length);
    // get rights
    get(Api.right.user)
      .then(_res => _res.json())
      .then(_res => {
        persistRights(_res.data);
        responseLogin({status: STATUS.OK, user: persist}, cb);
      })
      .catch(err => {
        responseLogin({status: STATUS.FAULT}, cb);
      });
  }else{
    responseLogin({status: STATUS.FAULT}, cb);
  }
};

// session Object
const session = {
  isAutenticated: () => {
    return (headers() && headers().Authorization);
  },
  authenticate: (params, cb) => {
    const pwd = hash(params.pwd);
    fetch(API_URL+'/user/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: params.email,
        client_id: pwd,
        ext: params.exten,
        client: isMobile?'mobile':'default'
      })
    })
      .then(res => res.json())
      .then(json => loginResult(json, params, cb))
      .catch(e => responseLogin(STATUS.FAULT, cb));
  },
  signout: (cb) => { 
    storage().removeItem(TAG_AOTH, false);
    storage().removeItem(TAG_USER);
    storage().removeItem(TAG_RIGHTS);
    removeConf();
    if(cb){
      setTimeout(cb, 200);
    }
  },
  user: () => {
    if(storage().getItem(TAG_USER)){
      return JSON.parse(atob(storage().getItem(TAG_USER)));
    }
    return null;
  },
  dashboardAge: () => {
    if(storage().getItem(TAG_USER)){
      return JSON.parse(atob(storage().getItem(TAG_USER))).domain_dashboard_age;
    }
    return 96;
  },
  isMaster: () => {
    if(storage().getItem(TAG_USER)){
      return (JSON.parse(atob(storage().getItem(TAG_USER))).lvl === LVL_USER_MASTER);
    }
    return false;
  },
  isAdmin: () => {
    if(storage().getItem(TAG_USER)){
      return (JSON.parse(atob(storage().getItem(TAG_USER))).lvl === LVL_USER_ADMIN);
    }
    return false;    
  },
  rights: {
    has: (path) => {
      if(storage().getItem(TAG_RIGHTS)){
        let rights = JSON.parse(atob(storage().getItem(TAG_RIGHTS)));
        return rights.indexOf(path);
      }
      return -1;
    },
    get: (id) => {
      if(id && userRights){
        return userRights.filter(r => r.id === id)[0];
      }
      return userRights;
    }
  },
  io: {
    join: (callback) => {
      const domain = JSON.parse(atob(storage().getItem(TAG_USER))).domain;
      if(domain){
        io = socketIOClient(`${SK_HOST}:${SK_PORT}`, {
          query: {token: headers().Authorization}
        });
        io.on('connect', () => {
          io.on(EVENT.MISSED_CALL, data => dispatch(EVENT.MISSED_CALL, data));
          io.on(EVENT.CHANNEL_CHANGE, data => dispatch(EVENT.CHANNEL_CHANGE, data));
        });
      }
    },
    quit: (domain) => {
      if(io){
        io.emit('logout', {uuid: domain});
        io.disconnect();
      }
    }
  },  
};

export default session;