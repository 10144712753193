/**
 * Utils constants
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 06/09/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */

export const Constants = {
  TEXT_APP_FOOTER: ' © 2021 - v. 1.0.6',
  TEXT_APP_OWNER: 'Zipy',
  TEXT_DASHBOARD_RESOLVE_CALL: 'Resolver chamada. A ligação ficou pendente porém o contato foi realizado com o cliente por outro meio de comunicação. Essa operação poderá ser auditada posteriormente.',
  TEXT_DASHBOARD_HIDE_NUMBER: 'Ocultar número. Quando um número é ocultado no dashboard, esse não será listado mesmo que tenha ligação pendente para o ele. Essa operação pode ser auditada posteriormente.',
  TEXT_DASHBOARD_HELP: 'Dashboard de Chamadas Perdidas mostra em tempo real a situação das chamadas que não foram atendidas. As estatísticas mostram o número de ligações que foram recebidas, retornadas e que estão pendentes com sua representação gráfica em porcentagem. As informações são colhidas com base nas últimas horas, informadas no canto superior direito da tela e com possibilidade de serem alteradas.',
  TEXT_ACTIVE_CALL: 'Indica uma liação perdida. Quando colorido indica que o número está em ligação.',
  TEXT_BRANCH_SELECTOR: 'Se tiver \'Centrais\' configurada para a conta, o usuário pode mudar a visualização do Dashboard.',
  TEXT_PENDING_STATS: 'Recebida: Chamada que estava como pendente e foi atendida em outra tentativa. Retornada: Chamada que estava pendente, foi retornada e foi atendida. Pendente: Chamada que não foi atendida.',
  TEXT_MAKE_CALL: 'Ao logar no sistema o usuário informa o ramal que ele está e ativa essa opção para realizar uma ligação.',
  TEXT_REFRESH: 'Atualiza manualmente as informações.',
  TEXT_BLOCKLIST_HELP: 'Números bloqueados são números que foram adicionados para não realizarem chamadas ou apenas ocultados para não contabilizar nos dados de chamadas perdidas. Quando um número é ocultado na tela de Dashboard de Chamadas Perdidas ele entra nessa lista, porém o número continua com permissão para fazer chamadas. Quando adicionado por essa tela, na opção (+), o número fica bloqueado para fazer chamadas.',
  TEXT_BLOCKLIST_IC_ADD: 'Habilita o formulário para adicionar manualmente um número a lista de bloqueio. Basta preencher os campos obrigatórios e salvar.',
  TEXT_BLOCKLIST_IC_DELETE: 'Remove um número da lista de bloqueio.',
  TEXT_OP_ADITING: 'Todas as operações realizadas poderão ser auditadas posteriormente.',
  TEXT_CALL_REPORT_HELP: 'Com relatório de chamadas você pode verificar a situação das chamadas dentro de um período. Escolha a data de início, data final, o tipo de relatório com opção para buscar a partir de um número específico. O relatório pode ser sintético ou analítico quando acessado a partir de um computador, em um dispositivo móvel o relatório sempre é sintético para ter uma melhor visualização e rapidez na conslusão da leitura dos dados.',
  TEXT_CALL_REPORT_FILTER: 'Os filtros vão te auxiliar a otimizar sua busca selecionando os resultados mais relevantes. Você pode filtrar por duração de chamadas, se contém comentários ou gravação, o fluxo da ligação (entrada, saída ou entre ramais) e se a chamada possuí algum marcador*. Também pode restringir o horário selecionado, nesse caso ele vai restringir a busca com base na data selecionada e dentro da faixa de horário, por exemplo, se você escolher de 8h-12h a consulta irá ignorar ligações fora dessa faixa de horário.',
  TEXT_CALL_REPORT_EXPEDIENT: 'Ao selecionar o filtro "Apenas horário de expediente" o sistema irá trazer apenas as chamadas realizadas dentro do horário de expediente que foi configurado para conta. A opção "Apenas fora do expediente" vai fazer exatamente o contrário. Ambas as opções vão ser limitadas ao período selecionado nos campos de data inicial e final. Uma opção cancela a outra.',
  TEXT_CALL_REPORT_FILTER_M: 'Você pode filtrar por ligações recebidas, efetuadas ou entre ramais',
  TEXT_CALL_REPORT_MONTH: 'Ativa o seletor dos meses para visualizar um relatório sintético do um mês escolhido.',
  TEXT_CALL_REPORT_TAG: 'Adiciona ou remove marcadores no registro da ligação. Quando azul, indica que essa ligação possui marcadores.',
  TEXT_CALL_REPORT_AUDIO: 'Ouvir a gravação da ligação. Também indica quando uma ligação não possui áudio.',
  TEXT_CALL_REPORT_COMMENTS: 'Adiciona ou remove comentários no registro da ligação. Quando azul indica que essa ligação possui comentários.',
  TEXT_CALL_REPORT_BLOCK: 'Bloqueia um número para não receber chamadas do mesmo. Essa operação pode ser auditada posteriormente.',
  TEXT_TAGS: "Os marcadores são úteis para classificar as ligações por assunto. Por exemplo, o poerador pode adicionar uma marcação do tipo \"Reclamação\" e marcar a um registro de ligação.",
  TEXT_REPORT_TAGS: 'Você pode verificar a quantidade de marcações das ligações dentro de um perído de maneira sintética gerando assim um desempenho diário das marcações. Pode-se filtrar um ou mais marcadores para filtrar o resultado.',
  TEXT_LOGIN_HELP_EXTEN: 'Adicionar o ramal que você está utilizando abre algumas opções no sistema. Uma delas é a ligação com um clique, você pde clicar no número para fazer a chamada.',
  TEXT_HELP_CONTACT_LIST: 'A lista de contatos ajuda a organizar e identificar as ligações. Ao receber uma ligação se o número estiver na lista de contatos o nome do contato irá aparecer no registro da chamada.',
  TEXT_HELP_CONTACT_IMPORT: 'Importar arquivo CSV*. O arquivo deve ter o seguinte formato: nome,numero,email. O campo e-mail pode ficar em branco.',
  TEXT_HELP_CONTACT_EDIT: 'Abre o formulário com os dados do contato selecionado para edição. No mobile basta clicar no contato',
  TEXT_HELP_CONTACT_DELETE: 'Remove um contato da lista. Essa operação pode ser auditada posteriormente.',
  TEXT_HELP_CONTACT_ADD: 'Habilita o formulário para adicionar um novo contato. Basta preencher os campos obrigatórios e salvar.',
  TEXT_MISSED_REPORT_HELP: 'Com relatório de chamadas perdidas você pode verificar a situação das chamadas perdidas dentro de um período. Escolha a data de início, data final, o tipo de relatório com opção para buscar a partir de um número específico. O relatório pode ser sintético ou analítico quando acessado a partir de um computador, em um dispositivo móvel o relatório sempre é sintético para ter uma melhor visualização e rapidez na conslusão da leitura dos dados.',
  TEXT_MISSED_EXTENSION: 'Habilita o seletor para filtrar resultados apenas de um ramal específico.',
  TEXT_OPERATOR_PANEL_HELP: 'Painel do Operador mostra em tempo real a situação dos ramais com possibilidade de realizar algumas ações, como desligar ou fazer ligação com apenas um clique.',
  TEXT_CONFIG_USER_HELP: 'Altere as configurações dos usuários de sua conta. Você pode restringir o usuário acessar o sistema apenas em redes confiáveis assim como ver os endereços de IP que o usuário acessou, se foi dispositivo móvel ou computador, ou alterar as permissões de acesso, restricões de horários e até bloquear o acesso do usuário.',
  TEXT_CONFIG_ACESS: 'Alterar as configurações padrões de acesso. Toda alteração será aplicada a todos os usuários.',
  TEXT_TMRET: 'Tempo médio de retorno das chamadas perdidas. Em que o operador retornou a ligação',
  TEXT_TMA: 'Tempo médio de atendimento das chamadas perdidas. Em que o número retornou e foi atendido',
  TEXT_NOT_FOUND: 'Nenhum resultado encontrado.',
  TEXT_ACTION_OK: 'Operação realizada com sucesso.',
  TEXT_ERROR_DEFAULT: 'Ocorreu um erro',
  TEXT_NETWORK_ERROR: 'Ops! Você pode estar sem internet',
  TEXT_ERR_REQUIRED_FIELD: 'Os campos são obrigatórios',
  MOBILE_SSM_SCREEN_WIDTH: 320,
  LABEL_BT_SAVE: 'Salvar',
  LABEL_BT_CANCEL: 'Cancelar',
  LABEL_BT_BACK: 'Voltar',
  TEXT_INFO_NEW_VERSION: 'Nova versão disponível, pressione Ctrl + F5 para atualizar',
  TEXT_INFO_NEW_VERSION_MOBILE: 'Nova versão disponível.',
  IS_COMPACT_SCREEN: (window.innerWidth >= 960 && window.innerWidth <= 1444),
  IS_X_SMALL_SCREEN: (window.innerWidth >= 300 && window.innerWidth <= 360),
  CALL_REPORT_LABEL: 'Relatório Zipy',
};