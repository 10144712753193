/**
 * Show a message for user UI 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 12/11/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */

import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import {Constants} from '../../util/Constants';
import {isMobile} from 'react-device-detect';
import {TAG_EMAIL} from '../../session';


const UpdateButton = ({...props}) => {
  if(!isMobile) return null;
  return (
    <Button onClick={() => window.location.reload(true)}>
      Atualizar
    </Button>    
  );
};

class MessageNewVersion extends PureComponent{

  state = {
    show: false
  }

  _eventReceived = () => {
    localStorage.removeItem(TAG_EMAIL);
    this.setState({show: true});
  }

  componentDidMount(){
    document.addEventListener('newversion', this._eventReceived);
  }

  componentWillUnmount(){
    document.removeEventListener('newversion', this._eventReceived);
  }
  
  render(){
    const {show} = this.state;
    if(!show) return null;    
    return (
      <div id="hasupdate">
        {isMobile?Constants.TEXT_INFO_NEW_VERSION_MOBILE:Constants.TEXT_INFO_NEW_VERSION}
        <UpdateButton />
      </div>
    );
  };
}
export default MessageNewVersion;