/**
 * Show loading circle 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 18/07/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */
import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export const CircleProgress = ({...props}) => {
  return (
    <Box display="flex" justifyContent="center" m={1} p={1}>
      <CircularProgress color="secondary"/>
    </Box>    
  );
};