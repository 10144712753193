/**
 * Export buttons component 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 20/05/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */
import React from 'react';
import Pdf from '../../assets/images/Pdf';
import Csv from '../../assets/images/Csv';
import Xls from '../../assets/images/Xls';
import Print from '@material-ui/icons/Print';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import {getLogo64} from '../../assets/images/logo_zip64';

import {
  Tooltip,
  IconButton,
} from '@material-ui/core';

export const MARGIN_LEFT_PDF = 10;
export const MARGIN_TOP_PDF = 14;
export const MARGIN_BOTTOM_PDF = 10;

export const pdfTable = (doc, source, columns, title, exportOptions) => {
  const {footer,subText,afterGridRender} = exportOptions;

  if(source && columns){
    const header = (pdf) => {
      let doc = pdf.doc;
      // if(logo !== null){
      doc.addImage(getLogo64().data, 'PNG', pdf.settings.margin.left, 11, 25, 8);
      // }
      doc.setTextColor(40);
      let width = doc.internal.pageSize.getWidth() - pdf.settings.margin.left;  
      let top = pdf.settings.margin.top - 3;            
      // Header
      doc.text(title, pdf.settings.margin.left + 30, 14);
      if(subText){
        doc.setFontSize(12);
        doc.text(subText, pdf.settings.margin.left + 30, 20);
      }
      doc.setDrawColor(0, 0, 0);
      doc.line(pdf.settings.margin.left, top, width, top); 
      // footer
      let height = doc.internal.pageSize.getHeight() - pdf.settings.margin.bottom;
      doc.line(pdf.settings.margin.left, height, width, height);
      
      let str_page = "Página "+doc.internal.getNumberOfPages();
      if(footer){
        doc.setFontSize(10);
        doc.text(footer, (width/2) + footer.length, height + 4);
      }
      doc.setFontSize(8);
      doc.text(str_page, (width/2) + (str_page.length/2) - 2, height + 8);    
    };

    let _marginTop = 30;
    if(afterGridRender){
      _marginTop = 35;
      afterGridRender(doc);
    }

    let options = {
      didDrawPage: header,
      theme: 'grid',
      margin: {top: _marginTop}
    }
    doc.autoTable(columns, source, options);
  }else{
    doc.addImage(getLogo64().data, 'PNG', MARGIN_LEFT_PDF, 11, 28, 8);
    
    doc.setTextColor(40);
    let width = doc.internal.pageSize.getWidth() - MARGIN_LEFT_PDF;  
    let top = MARGIN_TOP_PDF - 3;            
    // Header
    doc.text(title, MARGIN_LEFT_PDF + 30, 14);
    if(subText){
      doc.setFontSize(12);
      doc.text(subText, MARGIN_LEFT_PDF + 30, 20);
    }
    doc.setDrawColor(0, 0, 0);
    doc.line(MARGIN_LEFT_PDF, top+10, width, top+10); 
    // footer
    let height = doc.internal.pageSize.getHeight() - MARGIN_BOTTOM_PDF;
    doc.line(MARGIN_LEFT_PDF, height, width, height);
    
    let str_page = "Página "+doc.internal.getNumberOfPages();
    if(footer){
      doc.setFontSize(10);
      doc.text(footer, (width/2) - (footer.length/2), height + 4);
    }
    doc.setFontSize(8);
    doc.text(str_page, (width/2) + (str_page.length/2), height + 8);
  }
};

export const ExportButtons = withStyles(theme => ({
  root: {
    position: 'relative',
  },
  right:{
    position: 'absolute',
    top: 0,
    right: 4,
    width: 200,
    textAlign: 'right'
  },
  inputSearch: {
    borderRadius: 3, 
    border: '1px solid #CCC', 
    padding: 6,
    marginRight: 4,
  }  
}))(props => {
  const {classes,onPdf,onFilter,position,onCsv,onPrint,onXls} = props;
  return (
    <div className={cx(classes.root, {[classes.right]: (position==='right')})}>
      {onFilter
        ? <input 
            type="text" 
            className={classes.inputSearch}
            onChange={onFilter}
            placeholder="Buscar" 
          />
        : null
      }
      {onXls
        ? <Tooltip title="Excel" placement="top">
            <IconButton onClick={onXls}>
              <Xls />
            </IconButton>
          </Tooltip>
        : null
      }
      {onPdf
        ? <Tooltip title="PDF" placement="top">
            <IconButton onClick={onPdf}>
              <Pdf />
            </IconButton>
         </Tooltip>
        : null
      }
      {onCsv
        ? <Tooltip title="CSV" placement="top">
            <IconButton onClick={onCsv}>
              <Csv />
            </IconButton>
          </Tooltip>
        : null
      }
      {onPrint
        ? <Tooltip title="Imprimir" placement="top">
            <IconButton onClick={onPrint}>
              <Print />
            </IconButton>     
          </Tooltip>
        : null
      }      
    </div>
  );
});