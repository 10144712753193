/**
 * Combine reducers
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 17/03/2020
 * 
 * Copyright 2020 Zenite Tecnologia.
 */

import {preferencesReducer} from './prefReducer';
import {combineReducers} from 'redux';

export const Reducers = combineReducers({
  preferencesState: preferencesReducer,
});