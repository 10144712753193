/**
 * Creating nonce hash 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 04/09/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */

const md5 = require('md5');

// create 32-bits nonce string
const nonce = () => {
  const nmap = '0123456789abcdef&$*_/';
  let n = '', i = 0;
  for(; i < 32; i++){
    n += nmap.charAt(Math.floor(Math.random()*nmap.length));
  }
  return n;  
};

// create hash for string with nonce
const hash = (str) => {
  let n = nonce();
  return md5( md5(str) + md5(n) ) + n;
};


module.exports = hash;