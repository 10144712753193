/**
 * Load view components asynchronously.
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 06/05/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 * 
 * Usage
 * 
 * import {asyncComponent} from './AsyncComponent';
 * 
 * const HomeView = asyncComponent(() => import('./views/pages/Home'));
 * 
 * ...
 * render(){
 *    return (
 *      <HomeView />
 *    );
 * }
 * 
 */
import React, { Component } from "react";
import {CircleProgress} from './common/Loading';

export const asyncComponent = importComponent => {
  
  class AsyncComponent extends Component {
    constructor(props){
      super(props);
      this.state = {
        Component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();
      this.setState({Component: component});
    }

    render(){
      const {Component} = this.state;
      return Component ? <Component {...this.props} /> : <CircleProgress />;
    }

  }

  return AsyncComponent;
};