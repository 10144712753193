/**
 * Private router
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 09/06/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */
import React from "react";
import {Route, Redirect} from 'react-router-dom';
import session from '../session';

function PrivateRouter({component: Component, ...rest}){
  const {admin, path, search, notify, sharedObject} = rest;
  let pass = session.isAutenticated();
  if(admin){
    pass = (session.isAutenticated() && session.isMaster());
  }
  if(session.rights.has(path) < 0){
    pass = false;
  }
  return (
    <Route 
      {...rest}
      render={props => pass 
        ? <Component 
            sharedObject={sharedObject}
            parentSearch={search} 
            eventNotify={notify} 
            {...props}
          /> 
        : <Redirect
            to={{
              pathname: session.isAutenticated()?session.user().settings.user_default_route:'/login',
              state: {from: props.location}
            }}
          />
      }
    />
  );
}

export default PrivateRouter;