/**
 * Main component
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 06/05/2019
 * 
 *  ________ ______   __  __________ _   _ ___ _____ _____ 
 * |__  /_ _|  _ \ \ / / |__  / ____| \ | |_ _|_   _| ____|
 *   / / | || |_) \ V /    / /|  _| |  \| || |  | | |  _|  
 *  / /_ | ||  __/ | |    / /_| |___| |\  || |  | | | |___ 
 * /____|___|_|    |_|   /____|_____|_| \_|___| |_| |_____|
 *
 * Copyright 2019 Zenite Tecnologia.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import PrivateRouter from './routes/PrivateRouter';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch as RouterSwitch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MessageNewVersion from './components/common/MessageNewVersion';

import {default as primaryColor} from '@material-ui/core/colors/lightBlue';
import {default as secondaryColor} from '@material-ui/core/colors/purple';
import {default as actionColor} from '@material-ui/core/colors/lightGreen';

import {asyncComponent} from './components/AsyncComponent';

// mobile devices
import {isMobile} from 'react-device-detect';
import {Constants} from './util/Constants';

// redux
import {Provider} from 'react-redux';
import {Store} from './redux/store';

// is notebook screen. If true change density of layout
const isCompactScreen = Constants.IS_COMPACT_SCREEN?'small':'medium';

const AsyncHome   = asyncComponent(() => import('./views/Home'));
const AsyncAdmin  = asyncComponent(() => import('./views/Administrator'));
const AsyncApp    = asyncComponent(() => import('./App'));

const theme = createMuiTheme({
  props: {
    MuiButton: {
      size: isCompactScreen,
    }
  },  
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: primaryColor,
    secondary: {
      light: secondaryColor[100],
      main: secondaryColor[500],
      dark: secondaryColor[600],
      contrastText: secondaryColor[100]
    },
    action: actionColor,
  },
  fab: {
    position: 'fixed',
    bottom: 16,
    right: 16,
    zIndex: 1109
  },
  mobileContent: {
    '@media only screen and (max-width: 960px)': {
      paddingTop: 89
    }
  },
  mobileList: {
    width: '100%',
    paddingBottom: 72
  },
  headerPaper: {
    padding: 8,
    marginBottom: 12,
    '@media only screen and (max-width: 960px)': {
      position: 'fixed',
      backgroundColor: "#FFF",
      zIndex: 1099,
      width: '100%'
    }
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: 12,
        '@media only screen and (max-width: 1444px) and (min-width: 1200px)': {
          padding: 3,
          marginLeft: 4,
          marginRight: 4,          
        }
      }
    },
    MuiListItem: {
      root: {
        '@media only screen and (max-width: 1444px)': {
          paddingTop: 2,
          paddingBottom: 2,
        }
      }
    },
    MuiMenuItem: {
      root: {
        '@media only screen and (max-width: 1444px)': {
          fontSize: '0.8rem'
        }
      }      
    },
    MuiTypography: {
      body1: {
        '@media only screen and (max-width: 1444px)': {
          fontSize: (isMobile)?'1rem':'0.8rem',
        }
      }
    },
    MuiFormControl: {
      root: {
        '@media only screen and (max-width: 1444px)': {
          verticalAlign: 'baseline',
        }
      }
    },
    MuiSvgIcon: {
      root: {
        '@media only screen and (max-width: 1444px) and (min-width: 1200px)': {
          fontSize: "large"
        }
      }
    },

    // MuiInputBase: {
    //   root: {
    //     '@media only screen and (max-width: 1444px)': {
    //       fontSize: 12
    //     }
    //   }
    // }
  }
})

// main app router
const MainApp = ({...props}) => {
  return (
    <Router>
      <RouterSwitch>
        <Route 
          path="/login/" 
          exact
          component={AsyncApp}
        />
        <Route 
          path="/login/:code" 
          exact
          component={AsyncApp}
        />
        <PrivateRouter 
          path="/home" 
          component={AsyncHome} 
          />
        <PrivateRouter
          path="/admin"
          component={AsyncAdmin}
          admin
        />
        <Redirect 
          from="/" 
          to="/login" 
        />
      </RouterSwitch>
    </Router>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MessageNewVersion />
    <Provider store={Store}>
      <MainApp />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (worker) => {
    document.dispatchEvent(new CustomEvent('newversion', {}));
  }
});