/**
 * Make a HTTP request using base configuration  
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 06/05/2019
 * 
 * {
 *  method: 'POST',
 *  headers: {
 *    'Content-Type': 'application/json'
 *  },
 *  body: JSON.stringify({
 *    email: params.email,
 *    hash: pwd
 *  })
 * } 
 * 
 * Copyright 2020 Zenite Tecnologia.
 */

import session, {storage,API_URL} from '../session';
import {dateForPicker} from '../util/Utils';

const TAG_HEADER = 'rKg7qxcU_LEPCn.XlE4R8G';
export const SESSION_API = 'https://adm.zipy.com.br:35000';

// configure request
// base-url: https://domain.com/api
export const configRequest = ({baseUrl, headers}) => {
  // save base url
  //storage().setItem(TAG_URL, baseUrl);
  let hdrs = Object.assign(headers, {'Content-Type': 'application/json'});
  // save headers
  storage().setItem(TAG_HEADER, JSON.stringify(hdrs));
};

// update headers
export const updateToken = (newToken) => {
  let h = JSON.parse(storage().getItem(TAG_HEADER));
  h.Authorization = `Bearer ${newToken.replace('renew:', '')}`;
  // save new headers
  storage().setItem(TAG_HEADER, JSON.stringify(h));  
};

// remove config
export const removeConf = () => {
  storage().removeItem(TAG_HEADER);
  //storage().removeItem(TAG_URL);
};

export const headers = () => {
  const h = JSON.parse(storage().getItem(TAG_HEADER));
  if(h){
    h.now = dateForPicker();
    if(session.user().settings.branchs){
      h.Branch = session.user().settings.branchs;
    }
  }
  return h;
};

export const get = (endpoint, controller = null) => {
  return fetch(
    `${API_URL}${endpoint}`, {
      method: 'get',
      headers: headers(),
      signal: (controller)?controller.signal:null
    }
  );
};

export const post = (endpoint, {body}, controller = null) => {
  return fetch(
    `${API_URL}${endpoint}`, {
      method: 'post',
      headers: headers(),
      body: (body)?JSON.stringify(body):null,
      signal: (controller)?controller.signal:null
    }
  );
};

export const put = (endpoint, {body}, controller = null) => {
  return fetch(
    `${API_URL}${endpoint}`, {
      method: 'put',
      headers: headers(),
      body: (body)?JSON.stringify(body):null,
      signal: (controller)?controller.signal:null
    }
  );  
};

export const del = (endpoint, controller = null) => {
  return fetch(
    `${API_URL}${endpoint}`, {
      method: 'delete',
      headers: headers(),
      signal: (controller)?controller.signal:null
    }
  );
};

export const upload = (endpoint, file, controller = null) => {
  const hd = headers();
  delete hd['Content-Type'];
  return fetch(
    `${API_URL}${endpoint}`, {
      method: 'post',
      headers: hd,
      body: file,
      signal: (controller)?controller.signal:null
    }
  );  
}

/**
 * Check for error code in Json
 */
export const checkResult = json => {
  if(json.error && json.code === 401){
    session.signout( () => window.location.reload() );
  }
}

/** Api */
export const Api = {
  cdr: {
    dashboard: '/cdr/dashboard',
    resolveCall: '/cdr/missedok',
    report: {
      bytag: '/cdr/bytag',
      period: '/cdr/report',
      synthetic: '/cdr/synthetic',
      extenstats: '/cdr/extenstats',
      month: '/cdr/month',
      missed: '/cdr/missed',
      missedst: '/cdr/missedst',
    },
    extensions: '/cdr/extensions',
  },
  audit: {
    report: '/audit/report',
    types: '/audit/types'
  },
  domain: {
    branch: {
      list: '/branch',
      save: '/branch',
      hookies: '/branch/hookies',
      extensions: '/branch/extensions',
      ivrs: '/branch/ivrs',
      timegroups: '/branch/timegroups',
      ringroups: '/branch/ringroups',
      queues: '/branch/queues',
      voicemails: '/branch/voicemails',
      users: '/branch/users',
      setopt: '/branch/setopt',
      toggleall: '/branch/toggleall',
      savename: '/branch/setname',
    }
  },
  blacklist: {
    save: '/blacklist',
    remove: '/blacklist',
    list: '/blacklist',
    search: '/blacklist/search',
    delnum: '/blacklist/delnumber',
    regex: {
      main: '/regex',
      reorder: '/regex/reorder',
      delete: '/regex/delete'
    }
  },
  tags: {
    list: '/tags',
    save: '/tags',
    remove: '/tags',
    addcall: '/tags/addcall',
    report: '/tags/report',
    bycall: '/tags/getbycall',
    removecall: '/tags/removecall',
  },
  user: {
    save: '/user',
    list: '/user',
    update: '/user/update',
    settings: '/user/settings',
    password: '/user/password',
    toggle: '/user/toggle',
    bydomain: '/user/bydomain',
    search: '/user/search',
    levels: '/user/levels',
    exten: '/user/exten',
    wa: '/user/wa',
    tasks: {
      pending: '/task/pending',
      list: '/task',
      import: '/task/import'
    },
    domain: {
      list: '/user/domains',
      switch: '/user/switchdmain'
    }
  },
  right: {
    list: '/right',
    user: '/right/urights',
    operator: '/right/operator',
  },
  contact: {
    list: '/contact',
    search: '/contact/search',
    remove: '/contact/remove',
    save: '/contact/save',
    import: '/contact/import',
    originate: '/contact/originate',
  },
  settings: {
    toggle: '/user/toggleset',
    access: {
      list: '/access',
      save: '/access',
      delete: '/access',
      toggle: '/access/toggle',
      delipuser: '/access/delipuser',
      updateday: '/access/updateday',
      toggletime: '/access/toggletime',
      updateactime: '/access/updateactime',
      getactivetime: '/access/getactivetime',
      checkexpedient: '/access/checkexpedient',
    },
    message: {
      list: '/message',
      save: '/message',
    }
  },
  comment: {
    save: '/comment',
    delete: '/comment',
    bycall: '/comment/bycall',
  },
  app: {
    list: '/app'
  },
  fop: {
    operator: '/fop',
    command: '/fop/command'
  },
  tel: {
    types: '/telephone/events',
    report: {
      events: '/telephone/report'
    }
  }
}



