/**
 * Preferences reducer
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 17/03/2020
 * 
 * Copyright 2020 Zenite Tecnologia.
 */


import {ACTION_USER_PREF_SAVE} from '../actions/userPref';

const initPreferences = {
  userPreferences: {
    access: false,
  }
};

// Reducer user preferences
export const preferencesReducer = (state = initPreferences, action) => {
  switch(action.type){
    case ACTION_USER_PREF_SAVE: 
      return {...state, userPreferences: action.userPreferences};
    default:
      return state;
  }
}