/**
 * Helper functions
 * 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 14/05/2019
 * 
 * Copyright 2019 Zenite Tecnologia.
 */
import React from 'react';
import {pdfTable} from "../components/common/ExportHelper";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {Api,get} from '../net/NetworkUtils';
import session from '../session';
import cx from 'classnames';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {EVENT} from '../session';
import {isMobile} from 'react-device-detect';
import json2csv from 'json2csv';
//import XlsExport from 'xlsexport';
//import {Constants} from './Constants';

const limitDashboardAge = [
  {value: 24},
  {value: 48},
  {value: 72},
  {value: 96},
];

/** Helper for encapsulated style */
export const styledBy = (property, mapping) => props => mapping[props[property]];

export const defaultChartBarOptions = {
  title: {
    display: true,
    text: '',
  },
  legend: {
    labels: { boxWidth: 20, fontColor: 'black', fontSize: 10 },
    position: 'bottom',
    boxWidth: 15,
    fontSize: 8
  },
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 5,
      bottom: 5
    }
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [{ ticks: { beginAtZero: true }, display: true }],
    xAxes: [{ ticks: { beginAtZero: true }, display: true }]
  }
};

const putZero = (value) =>{
  return value<10?'0'+value:value;
}

const applyFormatDates = (dateString, month) => {
  let proc = dateString.trim().split(" ");
  return proc[3]+'-'+month+'-'+proc[2]+' '+proc[4];
};

export const timeToHuman = (num) => {
  let hours   = Math.floor(num / 3600);
  let minutes = Math.floor((num - (hours * 3600)) / 60);
  let seconds = num - (hours * 3600) - (minutes * 60); 
  
  if (hours   < 10 && hours > 0) hours   = "0"+hours;
  if (minutes < 10 && minutes > 0) minutes = "0"+minutes;
  if (seconds < 10 && seconds > 0) seconds = "0"+seconds;

  return `${hours}:${minutes}:${seconds}`;  
};

export const getMonthPeriod = month =>{
  let currentDate = new Date();
  let dtStart = new Date(currentDate.getFullYear(), month+1, 1, 0, 0);
  let dtEnd = new Date(currentDate.getFullYear(), month+1, 0, 23, 59);

  dtStart = applyFormatDates(dtStart.toString(), month+1);
  dtEnd = applyFormatDates(dtEnd.toString(), month+1);

  return {start: dtStart, end: dtEnd};
};

export const drawTotals = (chart) => {
  if(chart.config.options.centerText){
    let width = chart.chart.width,
    height = chart.chart.height,
    ctx = chart.chart.ctx;

    ctx.restore();
    let fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";

    let text = chart.config.options.centerText.text,
    textX = Math.round((width - ctx.measureText(text).width) / 2),
    textY = (height / 2) - 15;
    
    ctx.fillText(text, textX, textY);
    ctx.save();
  }
}

/**
 * Format date to datepicker
 */
export const dateForPicker = (date) => {
  if(!date){
    date = new Date();
  }
  return `${date.getFullYear()}-${putZero(date.getMonth()+1)}-${putZero(date.getDate())}T${putZero(date.getHours())}:${putZero(date.getMinutes())}`;
};

/**
 * Format time to datepicker
 */
export const timeForPicker = (date) => {
  if(!date){
    date = new Date();
  }
  return `${putZero(date.getHours())}:${putZero(date.getMinutes())}`;
};

/**
 * Format date to: YYYY/MM/DD HH:i:s
 */
export const dateForHuman = (date) => {
  if(!date){
    date = new Date();
  }
  return `${putZero(date.getDate())}/${putZero(date.getMonth()+1)}/${date.getFullYear()} ${putZero(date.getHours())}:${putZero(date.getMinutes())}`;
};

/**
 * Get random color
 * return: rgb(x,y,z);
 */
function randColor(alpha=.7){
  return 'rgba(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ', ' + alpha+')';
}

/**
 * Clone actual object
 * @param {Object} obj 
 */
export const cloneObject = obj => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Input formmat:
 * data: [{date: '<date>', foo: 1, bar: 2, ...}, ]
 * datasets: [{field: '<fieldname>', data:[], label: '<label>'}, ]
 * Output formmat:
 * {labels: [...], datasets: {...}}
 */
export const barDatasetParse = (data, datasets) => {
  let i = 0, y = 0, l=data.length;
  let labels=[];
  for(; i < l; i++){
    for(;y<datasets.length;y++){
      datasets[y].data.unshift( data[i][datasets[y].field] );
      datasets[y].backgroundColor = datasets[y].backgroundColor||randColor();
    }
    y = 0;
    labels.unshift(data[i].date);
  }
  return {labels: labels, datasets: datasets};
};

/**
 * Get sumary of bar dataset values
 */
export const pieSumaryDataset = (dataChart) => {
  let dataset=Object.assign([], dataChart.datasets), i=0, l=dataset.length,sumary=[];
  for(;i<l;i++){
    // sum the array values
  	sumary.push(dataset[i].data.reduce((a, b) => a + b, 0));
  }
  i=0;
  // mount array for labels and background colors
  let labels=[],colors=[];
  for(;i<l;i++){
    labels.push(dataset[i].label);
    colors.push(dataset[i].backgroundColor);
  }
  return {labels: labels, datasets: [{data: sumary, backgroundColor: colors}]}; 
};

/**
 * Get line chart dataset
 * The dataset is mounted in php
 */
export const getLineDataset = (data) => {
  // range to create label array: [0h, 1h, 2h...24h]
  let labels = Array.apply(null, Array(24)).map(function (_, i) {return ((i<10&&i>0)?'0'+i:i)+'h';});
  let dataset = [];
  for(let i in data){
    data[i].borderColor = data[i].backgroundColor;
    data[i].fill = false;
    dataset.push(data[i]);
  }
  if(isMobile){
    return getLieDatasetMobile(dataset, labels);
  }
  return {
    labels: labels, 
    datasets: dataset, 
    datalabels: {
      align: 'start',
      anchor: 'start'
    },
  };
}

/**
 * Line per hour mobile dataset
 */
const getLieDatasetMobile = (data, labels) => {
  const Row = (idx, value) => {
    return {
      idx: idx,
      value: value
    }
  };
  const check = (idx) => {
    let n = 0,l = data.length;
    for(;n<l;n++){
      if(data[n].data[idx] > 0) return true;
    }
    return false;
  };

  let i = 0, l = labels.length, _labels = [];
  for(; i < l; i++){
    if(check(i)){
      _labels.push(Row(i, labels[i]));
    }
  }
  i = 0;
  let k = _labels.length,  n = 0, tmp;
  l = data.length;
  for(;i<l;i++){
    tmp = [];
    n = 0;
    for(;n<k;n++){
      tmp.push(data[i].data[ _labels[n].idx ]);
    }
    data[i].data = tmp;
    data[i].fill = false;
    data[i].borderColor = data[i].backgroundColor;
  }

  return {
    labels: _labels.map(l => l.value), 
    datasets: data, 
    datalabels: {
      align: 'start',
      anchor: 'start'
    },
  };  
};

/**
 * Create custom legend for Pie chart
 */
export const customLegend = (chart) => {
  var text = [];
  text.push('<ul class="' + chart.id + '-legend">');
  var data = chart.data;
  var datasets = data.datasets;
  var labels = data.labels;
  if (datasets.length) {
     for (var i = 0; i < datasets[0].data.length; ++i) {
        text.push('<li><span style="background-color:' + datasets[0].backgroundColor[i] + '"></span>');
        if (labels[i]) {
           // calculate percentage
           var total = datasets[0].data.reduce(function(previousValue, currentValue) {
              return previousValue + currentValue;
           });
           var currentValue = datasets[0].data[i];
           var percentage = Math.floor(((currentValue / total) * 100) + 0.5);

           text.push(labels[i] + ' (' + percentage + '%)');
        }
        text.push('</li>');
     }
  }
  text.push('</ul>');
  return text.join('');  
}

/**
 * Show value of dataset line chart node
 */
export const chartValueNode = (chart, chartInstance) => {
  let ctx = chart.ctx;
  let fontSize = 16;
  let fontStyle = 'normal';
  let fontFamily = 'Helvetica Neue';
  let dataString;
  let meta;
  let position;
  ctx.font = chartInstance.helpers.fontString(fontSize, fontStyle, fontFamily);      
  ctx.textAlign = "center";
  ctx.textBaseline = "bottom";
  ctx.fillStyle = 'rgb(64, 64, 64)';
  let datasets = chart.data.datasets||chart.config.data.datasets;
  datasets.forEach((dataset, i) => {
    meta = chart.getDatasetMeta(i);
    if (!meta.hidden) {
      meta.data.forEach((element, index) => {
        if(dataset.data && dataset.data[index]){
          dataString = dataset.data[index].toString();
          if(dataString !== "0"){
            position = element.tooltipPosition();
            ctx.fillText(dataString, position.x, position.y - 5);
          }
        }
      });
    }
  });   
};

/**
 * Show value of dataset bar chart node
 */
export const chartBarValueNode = (chart, chartInstance) => {
  let ctx = chart.ctx;
  let fontSize = 16;
  let fontStyle = 'normal';
  let fontFamily = 'Helvetica Neue';
  let dataString;
  let padding = 10;
  let position;
  let meta;
  // Draw the text in black, with the specified font
  ctx.fillStyle = 'rgb(64, 64, 64)';
  ctx.font = chartInstance.helpers.fontString(fontSize, fontStyle, fontFamily);
  chart.data.datasets.forEach((dataset, i) => {
    meta = chart.getDatasetMeta(i);
    if (!meta.hidden) {
      meta.data.forEach((element, index) => {
        // Just naively convert to string for now
        if(dataset.data && dataset.data[index]){
          dataString = dataset.data[index].toString();
          if(dataString !== "0"){
            // Make sure alignment settings are correct
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            position = element.tooltipPosition();
            ctx.fillText(dataString, position.x, position.y + padding);//(fontSize / 2)));
          }
        }
      });
    }
  });  
};

/**
 * Draw chart background area
 */
export const _chartBackgroundArea = (chart) => {
    //var helpers = Chart.helpers;
    var ctx = chart.ctx;
    var chartArea = chart.chartArea;
    var canvas = chart.chart.canvas;
    
    ctx.save();
    ctx.fillStyle = chart.config.options.chartArea.backgroundColor||"#FFF";
    if(!chart.config.options.chartArea.fillArea){
      ctx.fillRect(
        chartArea.left,
        chartArea.top, 
        chartArea.right - chartArea.left, 
        chartArea.bottom - chartArea.top
      );
    }else{
      ctx.fillRect(0,0,canvas.width,canvas.height);        
    }
    ctx.restore();  
};

/**
 * Plugin registration for ChartBackgroundArea
 */
export const ChartBackgroundArea = {
  beforeDraw: (chart) => {
    if(chart.options.chartArea && chart.options.chartArea.fillArea){
      _chartBackgroundArea(chart);
    }
  }
};

/**
 * Mount dataset for Missed Call with focus on loss
 */
export const dstMissedCall = ({dataChart, ...props}) => {
  const {withLabel, showLegend} = props;
  let data = {labels: [], datasets: []}, i=0;
  for(; i<dataChart.length;i++){
    data.datasets.push({
      field: (withLabel)?dataChart[i][withLabel]:'', 
      label: (withLabel)?dataChart[i][withLabel]:'', 
      backgroundColor: dataChart[i].backgroundColor||randColor(), 
      data: [dataChart[i].value]
    });
    if(showLegend)
      data.labels.push(dataChart[i].name);
  }
  return data;
};

const disposition = {
  lost: {label: 'Perdidas', color: 'rgba(251, 37, 37, .8)'},
  returneds: {label: 'Retornadas', color: 'rgba(221, 57, 57, .3)'}, 
  receiveds: {label: 'Recebidas', color: 'rgba(84, 196, 110, .3)'}, 
  pendings: {label: 'Pendentes', color: 'rgba(76, 143, 232, .3)'},
};

/**
 * Mount dataseries for line chart (per hour)
 * (focused on loss)
 */
export const perHourMissedCall = (data) => {
  let dataseries = [], current = [];
  for(let i in data){
    current = [];
    for(let k in data[i]){
      current.push(data[i][k].value);
    }
    dataseries.push({
      label: disposition[i].label,
      data: current,
      backgroundColor: disposition[i].color||randColor(.5),
      borderColor: disposition[i].color||randColor(.5),
      fill: false
    })
  }
  // range to create label array: [0h, 1h, 2h...24h]
  
  let labels = Array.apply(null, Array(24)).map(function (_, i) {return ((i<10&&i>0)?'0'+i:i)+'h';});

  return {labels: labels, datasets: dataseries};
};

/**
 * Mount dataseries for line chart (per day)
 * (focused on loss)
 */
export const perDayMissedCall = (data) => {
  let labels = [], serie = [], dataseries = [];
  for(let i in data){
    serie = [];
    for(let k in data[i]){
      if(labels.indexOf(data[i][k].data) < 0) labels.push(data[i][k].data);
      serie.push(data[i][k].value);
    }
    dataseries.push({
      label: disposition[i].label,
      data: serie,
      backgroundColor: disposition[i].color||randColor(.5),
      borderColor: disposition[i].color||randColor(.5),
      fill: false      
    });
  }
  return {labels: labels, datasets: dataseries};
};

/**
 * Export data to PDF file
 */
export const toPDF = (columns, source, title, options, save=true) => {
  let doc = new jsPDF('landscape');
  pdfTable(doc,source,columns,title,options); // @see components/common/ExportHelper.js
  if(save){
    doc.save("zipy_report_"+new Date().getTime()+".pdf");
  }else{
    return doc;
  }
}

/**
 * Grid header array object to single Object based on datakey
 * @param {array} columns
 * input array: [{dataKey: 'foo'...}, {dataKey: 'bar'...},...]
 * output object: {"foo": "", "bar": ""}
 */
const columnsToObject = columns => {
  let strObj = "{";
  columns.forEach((r, i) => {
    if(r.dataKey !== '')
      strObj += `"${r.dataKey}": "",`;
  });
  strObj = strObj.substring(0, strObj.length-1);
  strObj += '}';
  return JSON.parse(strObj);
};

/**
 * Prepare data for export csv file based in array columns
 * @param {array} columns 
 * @param {array} data 
 * input columns [{dataKey: 'foo'...}, {dataKey: 'bar'...},...]
 *       data: [{foo: '...', bar: '...', too: '...'},...]
 * output: [{"foo": "", "bar": ""}, {"foo": "", "bar": ""}...]
 */
export const prepareDataToScv = (columns, data) => {
  let _default = columnsToObject(columns.filter(r => !r.ignoreExport)), result = [], clone;
  data.forEach((row, i) => {
    clone = Object.assign({}, _default);
    Object.keys(row).forEach((key) => { 
      if(key in clone){
        clone[key] = row[key];
      }
    });
    result.push(clone);
  });
  return result;
};

/**
 * Export to CSV
 * @param data - mixed array
 */
export const toCSV = (data, filename) => {
  if(!filename) filename = `zipy_${new Date().getTime()}.csv`;
  let csv = json2csv.parse(data);
  csv = csv.replace(/^(.*)\n/m, '');
  const blob = new window.Blob([csv], {
    type: "text/csv;charset=utf-8;"
  });
  if(navigator.msSaveBlob){
    navigator.msSaveBlob(blob, filename);
  }else{
    let hiddenA = window.document.createElement('a');
    hiddenA.href = window.URL.createObjectURL(blob);
    hiddenA.setAttribute('visibility', 'hidden');
    hiddenA.download = filename;
    document.body.appendChild(hiddenA);
    hiddenA.click();
    document.body.removeChild(hiddenA);
    console.log("Caiu aqui?");
  }
};

/**
 * Calcule percentagem
 */
export const Percent = (value, total) => {
  return value>0 ? Math.round( (value/total)*100) : 0;
};

/**
 * Get dashboard data
 */
export async function fetchMissedCall(){
  try{
    let response = await get(`${Api.cdr.dashboard}/${session.user().settings.user_dashboard_age}`);
    let data = await response.json();
    document.dispatchEvent(new CustomEvent(EVENT.MISSED_CALL, {detail: data}));
  }catch(err){  }
}

const MySnackbarContentWrapper = ({...props}) => {
  const classes = makeStyles (theme => ({
    success: {
      backgroundColor: green[500],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },    
  }))();
  const {message,onClose,variant,...others} = props;
  return (
    <SnackbarContent 
      className={cx(classes[variant])}
      message={
        <span id="my-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={onClose?[]:[]}
      {...others}
    />
  );
};

/**
 * Show a toast message
 * {open: boolean, message: string, onClose: fn, variant: [error|success]}
 */
export const MiniToast = ({...props}) => {
  const {variant,open,onClose,message} = props;
  return (
    <Snackbar 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MySnackbarContentWrapper 
        message={message}
        variant={variant}
      />
    </Snackbar>
  );
};

/**
 * Extract difference beteween dates
 * @param {Date} date 
 */
export const diffDates = date => {
  let current = new Date();
  let oneDay = 24*60*60*1000,
      hour = 1*60*60*1000,
      min = 1*60*1000,
      sec = 1*1000;
  let days = Math.round(Math.abs((date.getTime() - current.getTime())/(oneDay)));
  let hours = Math.round(Math.abs((date.getTime() - current.getTime())/(hour)));
  let minutes = Math.round(Math.abs((date.getTime() - current.getTime())/(min)));
  let secounds = Math.round(Math.abs((date.getTime() - current.getTime())/(sec)));
  return {days: days, hours: hours, minutes: minutes, secounds: secounds};  
};

/**
 * Show a string age beteween actual date
 * @param {Date} date 
 */
export const getDateAgo = date => {
  let diff = diffDates(date);
  if(diff.days > 0){
    if(diff.days > 360) return '+1 ano';
    if(diff.days < 30) return diff.days + ' d';//((diff.days > 1)?' dias':' dia');
    if(diff.days > 30) return `${Math.floor(diff.days/30)} mese(s)`;
  }else if(diff.days <= 0 && diff.hours > 0){
    return diff.hours + " h";
  }else if(diff.days <= 0 && diff.hours <= 0 && diff.minutes > 0){
    return diff.minutes + "min";
  }else if(diff.days <= 0 && diff.hours <= 0 && diff.minutes <= 0 && diff.secounds >= 0){
    return diff.secounds + " s";
  }
};

/**
 * Dashboard age array for select picker
 */
export const dashboardAgeValues = () => ([
  ...limitDashboardAge.filter(h => h.value < session.dashboardAge()),
  { value: session.dashboardAge() }
]);

/**
 * List of colors for tags
 */
export const tagColors = [
  {color: "#0033CC"},
  {color: "#428BCA"},
  {color: "#6cd8f0"},
  {color: "#44AD8E"},
  {color: "#A8D695"},
  {color: "#5CB85C"},
  {color: "#69D100"},
  {color: "#cef542"},
  {color: "#004E00"},
  {color: "#34495E"},
  {color: "#7F8C8D"},
  {color: "#f547ef"},
  {color: "#A295D6"},
  {color: "#5843AD"},
  {color: "#8E44AD"},
  {color: "#FFECDB"},
  {color: "#AD4363"},
  {color: "#D10069"},
  {color: "#CC0033"},
  {color: "#FF0000"},
  {color: "#D9534F"},
  {color: "#D1D100"},
  {color: "#F0AD4E"},
  {color: "#AD8D43"},
  {color: "#5d4037"},
  {color: "#979991"} 
];

/**
 * Ucfirst
 */
export const ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Check field is valid
 */
 export const isEmpty = value => (value === 0 || value === '' || typeof value === 'undefined' || value === null);